import React, { useState, useEffect, useRef, useContext } from 'react';

import "./home.css";
import mysqlServices from '../../services/mysqlServices';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from "react-lazy-load-image-component";

import homeImg from "../../assets/images/home/home_img_1-min_11zon.jpg"
import homeBg from "../../assets/images/home/home_img_2Cropped_11zon.jpg"

function Home() {


    const ref = useRef(true);

    const { t, i18n } = useTranslation();
    // const navigate = useNavigate();
    useEffect(() => {
        if (ref.current) {
            console.log(document.getElementById("cs"))
            // the strict mode in React 18 will mount unmount and remount the component whihc is why we needed to prevent that
            console.log("s")
            ref.current = false;

            // mysqlServices.getHomeTickets().then(data => {
            //     let tickets = data.data;
            //     console.log(tickets)
            //     setTickets(tickets);

            // })

        }
    }, [])



    return (
        <div className='Home-Container'>
            <div className='position-relative Home-Top-Div'>
                <img className='Home-Img-BG' src={homeBg} alt='home background' />
                <div className='Home-Text-Div1'>
                    {t('The We Matter Project is committed to uplifting the holistic well-being of young African, Caribbean, and Black women, non-binary, and trans youth in the Toronto and Peel regions. Our mission is to enhance mental, emotional, physical, and social health outcomes by fostering a supportive environment that addresses unique challenges. We strive to create inclusive spaces, eliminate stigma, and increase accessibility to healthcare services. Through collaborative efforts, cultural responsiveness, and community engagement, we aim to contribute to the overall positive health landscape, ensuring that young individuals in our target demographic experience a profound and enduring improvement in their well-being.')
                    }

                </div>
            </div>



            <div className=' justify-content-center'>
                {/* <LazyLoadImage src={homeImg}
                    className='Home-Image1'
                    alt='wematter img'
                /> */}
                <img src={homeImg} className='Home-Image1' alt='wematter img' />

            </div>

        </div>
    );
}

export default Home;