import axios from "axios";
import { appConstants } from "../constants/appConstants";
import { apiConstants } from "../constants/apiContstants";

async function sendEmail(input, token){
    console.log(`${appConstants.BASE_URL}/${apiConstants.POST.EMAIL.SEND_EMAIL}`);
    return new Promise((resolve,reject)=>{
        try {
            axios.post(`${appConstants.BASE_URL}/${apiConstants.POST.EMAIL.SEND_EMAIL}`,{"input":input, "token":token}).then((res)=>{
                resolve(res);
            }).catch((err)=>{
                reject(err);
            })
        } catch(err){
            reject(err)
        }
    })
}

const emailServices = {
    sendEmail
}

export default emailServices;