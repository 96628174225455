import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
// import advocasyImg from "../../assets/images/about/advocacy timeline1.png";
// import advocasyImg2 from "../../assets/images/about/Advocacy timeline2.png";
// import approachImg from "../../assets/images/about/WHIWH approach diagram.png";
// import logo from "../../assets/images/logos/Flourish_final.png";
// import WhiwhContact from "../snippets.components/whiwhContant.component";
// import whiwhApproach from "../../assets/images/about/whiwh's approach.PNG";
import pngImg1 from "../../assets/images/about us/png1.png"
import "./getInvolved.css";
import { IoIosArrowRoundForward } from "react-icons/io";
import Esther from "../../assets/images/get involved/Esther N.jpg";
import Ekemini from "../../assets/images/get involved/Ekemini Oku.jpg";
import Abigaelle from "../../assets/images/get involved/Abigaelle Matondo.jpg";
import unknown from "../../assets/images/get involved/blankFemaleImg.png"
function GetInvolved(params) {
    const { t, i18n } = useTranslation();
    const [windowSize, setWindowSize] = useState(window.innerWidth);

    const handleResize = () => {
        setWindowSize(window.innerWidth);
    }

    useEffect(() => {

        window.addEventListener("resize", handleResize);

        return () => {
            //    window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const stories = [
        {
            name: "Ruth Abdi",
            text: t("I am a second-year student pursuing a double major in Immunology and Global Health at the University of Toronto. The We Matter Project resonates with the social advocacy part of my education, prompting me to join the Youth Advisory Council. I appreciate the opportunity to contribute to research aimed at improving healthcare access in the Greater Toronto Area (GTA), drawing upon my diverse experience as an immigrant of African descent."),
            img: null
        },
        {
            name: "Fernanda Morales Rodriguez",
            text: t("Hi! My name is Fernanda and lm a latin youth who is passionate in community engagement. I joined to the YAC to develop my leadership skills and have a better understanding about youth serving organizations and how to provide a better service to unrepresented communities."),
            img: null
        },
        {
            name: "Aisha",
            text: [t("Name: Aisha B"), t("Age: 17"), t("Education: Grade 12, High School"), t("About me: I am passionate about social justice and the topic of intersectionality. I love travelling for fun and new experiences. I enjoy practising French as my second language."), t("Why I Decided to Join YAC: I decided to join the Youth Advisory Council because I was intrigued by the extension of support that it provides to women of marginalized backgrounds. The goals, principles, and foundation of YAC, specifically the We Matter Project align deeply with my morals. Being a part of the YAC is an excellent way for me to align with the woman that I want to become.")],
            img: null
        },
        {
            name: "Abigaelle Matondo",
            text: [t("Hello, "),
                "",
            t(`
                    My name is Abigaelle Matondo, I am currently a fourth year undergraduate at the University of Toronto studying Human Biology and aiming to pursue a higher education in fields focusing on the health of populations while also considering the sociocultural context behind those groups.I joined the Youth Advisory Council to educate myself on the conversation surrounding women’s health, mainly geared towards women of racialized communities who are scarcely underrepresented in online discourse and greatly overlooked in topics rooted in healthcare.From this amazing opportunity, I hope to learn more from my peers and myself while finding new ways to advocate for the importance of our voices within the medical system, allowing us to frame the context of our own bodies in a humanist approach.
                    `)],
            img: Abigaelle
        },
        {
            name: "Ekemini Oku",
            text: [t("Hi there, my name is Ekemini Oku. I work in the field of global health, striving to improve the health of those who have historically been marginalized and disadvantaged to provide them with the resources they need to be financially, socially, and environmentally healthy. By joining the YAC program I hope to take advantage of this opportunity to learn more about how we can empower black women to take control of their health journeys and achieve good health for themselves."),
                "",
            t("Thanks"),
                "Ekemini"
            ],
            img: Ekemini
        },
        {
            name: "Esther N",
            text: t("Esther Namalwa is a black young cis-woman. She has a bachelor's degree in community psychology and is passionate in providing emotional support. She has volunteered at PWA and works with ACT in the Women's program. She believes in empowering and building capacity among women."),
            img: Esther
        },

    ]

    return (
        <div className='About-Container-Wrap'>
            <div className='About-Container'>
                <span className="margin-top-20-px"></span>
                <span className="About-Img-Wrap-Medium bold">
                    {t("YAC COUNCIL")}
                </span>

                <div className="Get-Involved-Card-Container">
                    {stories.map((item, index) =>
                        <div className="Get-Involved-Card-Wrap">
                            {console.log(index, index % 2)}
                            {(index % 2 === 0 && (windowSize >= 1200)) ?
                                <div className="Get-Involved-Card-Text">
                                    <span className="bold">
                                        {item.name}
                                    </span>
                                    <span>
                                        {(item?.text && Array.isArray(item?.text)) ? 
                                        <div className="Get-Involved-Sub-Text">
                                          {item?.text.map(textItem => 
                                            <span>{textItem}</span>
                                            ) }
                                            </div>
                                            
                                        : <span>{item?.text}</span>
                                        }
                                    </span>
                                </div> :
                                <div>
                                    <img className="Get-Invovled-Card-Img" src={item?.img ?? unknown} alt={`Image of ${item.name}`} />
                                </div>
                            }
                            {(index % 2 === 0 && (windowSize >= 1200)) ?
                                <div>
                                    <img className="Get-Invovled-Card-Img" src={item?.img ?? unknown} alt={`Image of ${item.name}`} />
                                </div> :
                                <div className="Get-Involved-Card-Text">
                                    <span className="bold">
                                        {item.name}
                                    </span>
                                    <span>
                                        {(item?.text && Array.isArray(item?.text)) ?
                                            <div className="Get-Involved-Sub-Text">
                                                {item?.text.map(textItem =>
                                                    <span>{textItem}</span>
                                                )}
                                            </div>

                                            : <span>{item?.text}</span>
                                        }
                                    </span>
                                </div>
                            }
                        </div>
                    )}
                </div>

            </div>
        </div>
    )
}
export default GetInvolved;