import React from "react";
import { useTranslation } from "react-i18next";
// import advocasyImg from "../../assets/images/about/advocacy timeline1.png";
// import advocasyImg2 from "../../assets/images/about/Advocacy timeline2.png";
// import approachImg from "../../assets/images/about/WHIWH approach diagram.png";
// import logo from "../../assets/images/logos/Flourish_final.png";
// import WhiwhContact from "../snippets.components/whiwhContant.component";
// import whiwhApproach from "../../assets/images/about/whiwh's approach.PNG";
import pngImg1 from "../../assets/images/about us/png1.png"
import "./about.css";
import { IoIosArrowRoundForward } from "react-icons/io";

function About(params) {
    const { t, i18n } = useTranslation();

    return (
        <div className='About-Container-Wrap'>
            <div className='About-Container'>
                <span className="margin-top-20-px"></span>
                <span className="About-Img-Wrap-Medium bold">
                    {t("About WHIWH CHC")}
                </span>
                <span className="About-Text">
                    {t("WHIWH inclusive multidisciplinary services include primary medical care for all women from the age of 16, mental health support programs with individual and group counselling, self-advocacy programs, health promotion and education programs, Pre and Post Natal Care programs, HIV-AIDS Prevention, Support and Care, healthy living activities, support groups, supportive self-care activities and a resource information center. Our staff complement includes physicians, nurse practitioners, nurses, mental health therapists, health promoters, community health workers, dieticians, a chiropodist and a social worker. All programs at WHIWH go through an extensive peer-review process in accordance with accreditation standards which require target group contributions in terms of program development and delivery. WHIWH membership and Board is made up solely of target group members. The Board sets the policies of the Centre and the general membership have an opportunity to provide input into policies and Centre functioning at the annual general meeting. WHIWH conducts client feedback and evaluation opportunities several times per year to assist in the shaping of all Centre programs. We include client feedback in the shaping of our strategic plans every three years.")}
                </span>
                <img src={pngImg1} alt="pngImg" className="About-Image" />
                <span className="About-Img-Wrap-Medium bold">
                    {t("We Matter Project")}
                </span>
                <span className="bold">
                    {t("Mission Statement|Goals|Outcomes")}
                </span>
                <div className="About-Div-Holder">
                    <div className="About-Div-Sub-Holder">
                        <span className="bold underline">
                            {t("Mission statement")}
                        </span>
                        <span className="About-Text-1">
                            {t("The We Matter Project is committed to uplifting the holistic well-being of young African, Caribbean, and Black women, non-binary, and trans youth in the Toronto and Peel regions. Our mission is to enhance mental, emotional, physical, and social health outcomes by fostering a supportive environment that addresses unique challenges. We strive to create inclusive spaces, eliminate stigma, and increase accessibility to healthcare services. Through collaborative efforts, cultural responsiveness, and community engagement, we aim to contribute to the overall positive health landscape, ensuring that young individuals in our target demographic experience a profound and enduring improvement in their well-being.")}
                        </span>
                    </div>
                    <div className="About-Div-Sub-Holder">
                        <span className="bold underline">
                            {t("Vision")}
                        </span>
                        <span className="About-Text-1">
                            {t("To achieve optimal health, safety and wellbeing for all racialized young women, trans and non-binary people across their lifespan in Canada.")}
                        </span>
                    </div>
                    <div className="About-Div-Sub-Holder">
                        <span className="bold underline">
                            {t("Goals")}
                        </span>
                        <span className="About-Text-1">
                            {t("")}
                        </span>
                    </div>
                    <div className="About-Div-Sub-Holder">
                        <span className="bold underline">
                            {t("Outcomes")}
                        </span>
                        <div>
                            <span className="bold">
                                {t("Advocating for young ACB women, non-binary and trans youth")}
                            </span>
                            <div className="display-flex flex-wrap">
                                <span className="About-Arrow">

                                    <IoIosArrowRoundForward />
                                </span>
                                <span className="About-Text-1">
                                    {t("By providing access to safer spaces, frameworks, and wellness services that prioritize their health and social needs")}
                                </span>
                            </div>
                        </div>
                        <div>
                            <span className="bold">
                                {t("Adapting health service delivery")}
                            </span>
                            <div className="display-flex flex-wrap">
                                <span className="About-Arrow">

                                    <IoIosArrowRoundForward />
                                </span>
                                <span className="About-Text-1">
                                    {t("Through strategic, unique partnerships aimed at structural and policy change to address the health needs of young ACB women, non-binary and trans youth")}
                                </span>
                            </div>
                        </div>
                        <div>
                            <span className="bold">
                                {t("Affecting laws and policies")}
                            </span>
                            <div className="display-flex flex-wrap">
                                <span className="About-Arrow">

                                    <IoIosArrowRoundForward />
                                </span>
                                <span className="About-Text-1">
                                    {t("With a dynamic service delivery framework created through a raced, survivor-centric, gender and culturally responsive lens, available for service providers to facilitate linkage to health services.")}
                                </span>
                            </div>
                        </div>

                    </div>
                    <div className="About-Div-Sub-Holder column-gap-15-px row-gap-15-px">
                        <span className="bold underline">
                            {t("Key Objectives")}
                        </span>
                        <span className="About-Text-1">
                            <span className="bold underline">
                                {t("Mental Health")}
                            </span>
                            <span className="bold">{": "}</span>
                            {t("Implement initiatives that promote mental well-being, address stigma, and provide support for mental health challenges specific to the target demographic.")}
                        </span>
                        <span className="About-Text-1">
                            <span className="bold underline">
                                {t("Emotional Health")}
                            </span>
                            <span className="bold">{": "}</span>
                            {t("Create a supportive environment that acknowledges and addresses emotional needs, fostering resilience and coping mechanisms.")}
                        </span>
                        <span className="About-Text-1">
                            <span className="bold underline">
                                {t("Physical Health")}
                            </span>
                            <span className="bold">{": "}</span>
                            {t("Increase accessibility to healthcare services tailored to the unique health requirements of young African, Caribbean, and Black individuals, ensuring preventive and specialized care.")}
                        </span>
                        <span className="About-Text-1">
                            <span className="bold underline">
                                {t("Social Health")}
                            </span>
                            <span className="bold">{": "}</span>
                            {t("Build community connections, safer spaces, and supportive networks to enhance the social health of participants, recognizing the importance of cultural responsiveness and inclusivity.")}
                        </span>
                        <span className="About-Text-1">
                            <span className="bold underline">
                                {t("Accessibility")}
                            </span>
                            <span className="bold">{": "}</span>
                            {t("Improve access to health services by addressing barriers and collaborating with community stakeholders to create inclusive, welcoming, and culturally sensitive healthcare environments.")}
                        </span>
                        <span className="About-Text-1">
                            <span className="bold underline">
                                {t("Geographic Focus")}
                            </span>
                            <span className="bold">{": "}</span>
                            {t("Concentrate efforts on the Toronto and Peel regions to ensure targeted impact within the specific communities and neighborhoods where the identified youth population resides.")}
                        </span>
                        <span className="About-Text-1">
                            {t("Through these concerted efforts, the We Matter Project aims to contribute to the broader goal of creating positive health outcomes and increasing the accessibility of healthcare services, fostering a healthier and more supportive environment for young African, Caribbean, and Black women, non-binary, and trans youth in the Toronto and Peel regions.")}
                        </span>

                    </div>
                </div>
                <span className="margin-top-40-px"></span>
            </div>
        </div>
    )
}
export default About;