import React, { useState, useRef, useEffect, useContext } from "react";
import DisplayContent from "./displayMedia";
import axios from "axios";
import mysqlServices from "../../services/mysqlServices";
import { Link } from "react-router-dom"
import Spinner from 'react-bootstrap/Spinner';

import { AdminContext } from "../../App";
import adminServices from "../../services/adminServices";
// import Cookies from 'universal-cookie';

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";



function AdminPage(props) {
    // const cookies = new Cookies();
    const canLoad = useRef(true);
    const [display, setDisplay] = useState(false);

    const [file, setFile] = useState(null);
    const [fileToUpload, setFileToUpload] = useState(null);
    const [message, setMessage] = useState(null);
    const [fileName, setFileName] = useState("")
    const [fileExtension, setFileExtension] = useState("")

    const { setAdminLoggedIn } = props;

    const [adminPin, setAdminPin] = useState(null)


    const [loading, setLoading] = useState(false);

    const adminState = useContext(AdminContext)

    const [errorMessage, setErrorMessage] = useState(null)

    const [adminUsername, setAdminUsername] = useState("");
    const [adminPassword, setAdminPassword] = useState("");
    const [adminEmail, setAdminEmail] = useState("");

    const [adminLoginPending, setAdminLoginPending] = useState("false");
    const [pinCode, setPinCode] = useState("");

    const [spinnerOn, setSpinnerOn] = useState(false)
    const pendingTime = 120000;

    const [adminEmailLoggedIn, setAdminEmailLoggedIn] = useState("")

    const [survey, setSurvey] = useState(null);

    useEffect(() => {
        console.log("new state", adminState)
    }, [adminState])


    useEffect(() => {
        console.log(">>>>>>>>>>>>>loading admin page", canLoad.current)
        console.log('admin state', adminState)
        if (canLoad.current) {

            // let cookie = cookies.get("admin-user-cookie");
            // if (cookie) {
            //     let [email, adminCookie] = cookie.split("%");
            //     // console.log(email, adminCookie)
            //     setAdminEmailLoggedIn(email);
            //     setAdminLoggedIn("true");
            // }

            // mysqlServices.getSurveyQuestions().then(data => {
            //     let allQuestions = data.data;
            //     // console.log(allQuestions)
            //     let survey = {};
            //     for (let [key, value] of Object.entries(allQuestions)) {
            //         if (survey[`${value.question}%${value.answer}`]) {
            //             survey[`${value.question}%${value.answer}`] += 1;
            //         } else {
            //             survey[`${value.question}%${value.answer}`] = 1;
            //         }
            //     }
            //     let surveyResult = [];
            //     // console.log(survey)
            //     for (let [key, value] of Object.entries(survey)) {
            //         let question = key.split("%")[0]
            //         let answer = key.split("%")[1]
            //         let item = { question: question, answer: answer, number: value }
            //         surveyResult.push(item);
            //     }

            //     let finalResults = {};
            //     for (let item of surveyResult) {
            //         if (finalResults[item.question]) {
            //             finalResults[item.question].push({ "answer": item.answer, "number": item.number })
            //         } else {
            //             finalResults[item.question] = [{ "answer": item.answer, "number": item.number }]
            //         }

            //     }

            //     let finalResults2 = [];
            //     for (let [key, value] of Object.entries(finalResults)) {
            //         let item = { question: key, answers: value }
            //         finalResults2.push(item);
            //     }


            //     // console.log(finalResults2)
            //     setSurvey(finalResults2);

            // })


        } else {

        }
    }, [])


    const handleFileChange = (e) => {
        setMessage(null);
        let imgSrc = e.target.files[0]
        let fullName = imgSrc.name;
        let name = fullName.split(".")[0]
        let extension = fullName.split(".")[1]
        setFileName(name);
        setFileExtension(extension)

        var reader = new FileReader();
        // let mg = document.getElementById("myimg");
        // mg.tilte = imgSrc.name;
        console.log(imgSrc)
        reader.onload = function (e) {
            let sr = e.target.result
            setFile(sr)

            var newImage = document.createElement('img');
            newImage.src = sr;

            document.getElementById("imgTest").innerHTML = newImage.outerHTML;
            // alert("Converted Base64 version is " + document.getElementById("imgTest").innerHTML);
            console.log("Converted Base64 version is " + document.getElementById("imgTest").innerHTML);
            let inn = document.getElementById("imgTest").innerHTML;

            // we need to take only the base64 code here
            let v = inn.split(",")[1]
            v = v.substring(0, v.length - 2)
            // console.log(v)
            // console.log("____")
            // console.log(v.substring(0, v.length - 2))
            setFileToUpload(v)

        }

        //we will check the file type here 
        reader.readAsDataURL(imgSrc);

    }

    const handleSubmitFile = () => {
        let randomFileExtension = Math.random().toString();
        console.log(randomFileExtension)
        // we need to get a unique name here for our file from the database
        if (!fileToUpload || !fileExtension || fileExtension === "" || !fileName || fileName === "") {
            return;
        }
        mysqlServices.uploadMedia({ "file": fileToUpload, "extension": fileExtension, "fileName": fileName + "." + randomFileExtension }).then(data => {
            setMessage("image uploaded")
        })
        setFile(null)
        setFileExtension("")
        setFileName("")
        setFileToUpload(null)

    }

    useEffect(() => {
        setDisplay(true);

    }, [file])

    const handleFileName = (e) => {
        setFileName(e.target.value)
    }
    const handleFileExtension = (e) => {
        setFileExtension(e.target.value)
    }

    const handleAdminLogin = (value) => {
        if (value === "false") {
            // localStorage.removeItem("admin-user");
            // cookies.remove("admin-user-cookie")
            setAdminLoggedIn("false");
        } else if (value === "true") {
            // localStorage.setItem("admin-user", "true")
            // adminServices.setCookie().then(data => {
            //     console.log(data)
            // })

            setSpinnerOn(true);
            adminServices.adminLogin(adminUsername, adminPassword, adminEmail).then(data => {
                console.log(data)
                setSpinnerOn(false)
                if (data === "valid") {
                    setAdminLoginPending("true");
                    setTimeout(() => {
                        setAdminLoginPending("false")
                    }, pendingTime)
                }
            }).catch(err => {
                console.log(err);
                setSpinnerOn(false);
                setErrorMessage("Invalid Credentials")
            })


        }

    }

    const addPin = () => {
        console.log("updating pin")
        let objectWihoutId = { ...adminPin }
        if (Object.keys(objectWihoutId) < 1) {
            console.log("no entry")
            setErrorMessage("no entry")
            return;
        }
        if (objectWihoutId.lng && !validCoordinate("lng", objectWihoutId.lng)) {
            console.log("invalide lng")
            setErrorMessage("invalide lng val <= 180.0 && val >= -180.0")
            return;
        }
        if (objectWihoutId.lat && !validCoordinate("lat", objectWihoutId.lat)) {
            console.log("invalide lat")
            setErrorMessage("invalide lat val <= 90.0 && val >= -90.0")
            return;
        }
        setLoading(true);
        mysqlServices.addPin(objectWihoutId).then((data) => {
            console.log(data);
            setLoading(false);
        }).catch(err => {
            alert(err)
            setLoading(false);
        })
    }


    const handleChange = (target, value) => {
        console.log(target, value)
        let newPin = { ...adminPin };
        newPin[target] = value;
        setAdminPin(newPin);
    }

    const getC = () => {
        adminServices.getCookie()
    }

    //Latitude : max/min 90.0000000 to -90.0000000
    //Longitude: max / min 180.0000000 to - 180.0000000
    const validCoordinate = (t, val) => {
        if (t === "lng") {
            if (val <= 180.0 && val >= -180.0) {
                return true;
            } else {
                return false;
            }
        }
        else if (t === "lat") {
            if (val <= 90.0 && val >= -90.0) {
                return true;
            } else {
                return false;
            }
        }
    }

    const handleClose = () => {
        setErrorMessage(null);
    }

    const handleAdminAuthInput = (name, value) => {
        switch (name) {
            case "username":
                setAdminUsername(value);
                break;
            case "password":
                setAdminPassword(value);
                break;
            case "email":
                setAdminEmail(value);
                break;
            case "pinCode":
                setPinCode(value);
                break;
            default:
                return;
        }
    }

    const verifyAdmin = () => {
        setSpinnerOn(true)
        adminServices.verifyAdmin(pinCode, adminUsername, adminEmail).then(cookie => {
            if (cookie) {
                setAdminLoggedIn("true");
                // this will make the cookie expire after 1 day
                //cookies.set('admin-user-cookie', adminEmail+"%"+cookie, { path: '/', expires: new Date(Date.now() + 86400000) });
            } else {
                setErrorMessage("Error validating admin user")

            }
            setAdminLoginPending("false");
            setAdminUsername("");
            setAdminPassword("");
            setAdminEmail("")
            setSpinnerOn(false)
        }).catch(err => {
            console.log(err)
            setErrorMessage("Error validating admin user")
            setAdminLoginPending("false");
            setAdminUsername("");
            setAdminPassword("");
            setAdminEmail("")
            setSpinnerOn(false)
        })
    }

    return (
        <div className="">

            <Modal show={errorMessage !== null} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>System Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>{errorMessage + " !"}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>





            <div>


                <div className=''>

                    <div className='Admin-Pin-Admin-page'>
                        <div className="display-flex">
                            <span style={{ marginRight: "10px" }}>area</span>
                            <input type="text" placeholder="area" value={adminPin?.area ?? ""} onChange={(e) => { handleChange("area", e.currentTarget.value) }} />
                        </div>

                        <div className="display-flex">
                            <span style={{ marginRight: "10px" }}>remarks</span>
                            <input type="text" placeholder="remarks" value={adminPin?.remarks ?? ""} onChange={(e) => { handleChange("remarks", e.currentTarget.value) }} />
                        </div>

                        <div className="display-flex">
                            <span style={{ marginRight: "10px" }}>name</span>
                            <input type="text" placeholder="name" value={adminPin?.name ?? ""} onChange={(e) => { handleChange("name", e.target.value) }} />
                        </div>

                        <div className="display-flex">
                            <span style={{ marginRight: "10px" }}>phone</span>
                            <input type="text" placeholder="phone" value={adminPin?.phone ?? ""} onChange={(e) => { handleChange("phone", e.currentTarget.value) }} />
                        </div>

                        <div className="display-flex">
                            <span style={{ marginRight: "10px" }}>email</span>
                            <input type="text" placeholder="email" value={adminPin?.email ?? ""} onChange={(e) => { handleChange("email", e.currentTarget.value) }} />
                        </div>

                        <div className="display-flex">
                            <span style={{ marginRight: "10px" }}>address</span>
                            <input type="text" placeholder="address" value={adminPin?.address ?? ""} onChange={(e) => { handleChange("address", e.target.value) }} />
                        </div>

                        <div className="display-flex">
                            <span style={{ marginRight: "10px" }}>hours_of_operation</span>
                            <input type="text" placeholder="hours of operation" value={adminPin?.hours_of_operation ?? ""} onChange={(e) => { handleChange("hours_of_operation", e.target.value) }} />
                        </div>

                        <div className="display-flex">
                            <span style={{ marginRight: "10px" }}>description</span>
                            <input type="text" placeholder="description" value={adminPin?.description ?? ""} onChange={(e) => { handleChange("description", e.currentTarget.value) }} />
                        </div>

                        <div className="display-flex">
                            <span style={{ marginRight: "10px" }}>website</span>
                            <input type="text" placeholder="webiste" value={adminPin?.website ?? ""} onChange={(e) => { handleChange("website", e.currentTarget.value) }} />
                        </div>

                        <div className="display-flex">
                            <span style={{ marginRight: "10px" }}>facebook</span>
                            <input type="text" placeholder="facebook" value={adminPin?.facebook ?? ""} onChange={(e) => { handleChange("facebook", e.currentTarget.value) }} />
                        </div>

                        <div className="display-flex">
                            <span style={{ marginRight: "10px" }}>instagram</span>
                            <input type="text" placeholder="instagram" value={adminPin?.instagram ?? ""} onChange={(e) => { handleChange("instagram", e.currentTarget.value) }} />
                        </div>

                        <div className="display-flex">
                            <span style={{ marginRight: "10px" }}>twitter</span>
                            <input type="text" placeholder="twitter" value={adminPin?.twitter ?? ""} onChange={(e) => { handleChange("twitter", e.currentTarget.value) }} />
                        </div>

                        <div className="display-flex">
                            <span style={{ marginRight: "10px" }}>linkedin</span>
                            <input type="text" placeholder="linkedin" value={adminPin?.linkedin ?? ""} onChange={(e) => { handleChange("linkedin", e.currentTarget.value) }} />
                        </div>

                        <div className="display-flex">
                            <span style={{ marginRight: "10px" }}>lat</span>
                            <input type="number" placeholder="lat" value={adminPin?.lat ?? ""} onChange={(e) => { handleChange("lat", e.currentTarget.value) }} />
                        </div>

                        <div className="display-flex">
                            <span style={{ marginRight: "10px" }}>lng</span>
                            <input type="number" placeholder="lng" value={adminPin?.lng ?? ""} onChange={(e) => { handleChange("lng", e.currentTarget.value) }} />
                        </div>


                        <div className='display-flex margin-1-rem'>
                            <button onClick={() => { addPin() }} >Add Pin</button>
                            {loading ?
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner> : null}
                        </div>




                    </div>


                    {survey != null ?
                        <div className="background-color-light-pink margin-1-rem padding-1-rem">
                            <h3>Survey Results</h3>
                            {survey.map(item =>
                                <div className="">
                                    <p>{item.question}</p>
                                    {item.answers.map(item2 =>
                                        <div className="display-flex column-gap-1-rem">
                                            <p>{item2.answer}</p>
                                            <p>{item2.number}</p>
                                        </div>

                                    )}

                                    <hr />
                                </div>
                            )}
                        </div> : <div></div>
                    }


                </div>
            </div>


        </div>
    )
}
export default AdminPage;