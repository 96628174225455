import React, { useState, useEffect } from "react";
import "./contact.css";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import 'bootstrap/dist/css/bootstrap.css';
// import WhiwhContact from "../snippets.components/whiwhContant.component";
import { AiOutlineMail } from "react-icons/ai";
import { Link } from "react-router-dom";
import PrivacyPolicy from "../privacyPolicy.component/privacyPolicy.component";
import emailServices from "../../services/emailServices";
import { useTranslation } from 'react-i18next';
// import cookies from 'js-cookie';
// import contactUsImg from "../../assets/images/contact/Contact page.PNG";
import MameImg from "../../assets/images/contact us/mame.jpg";
import MonicaImg from "../../assets/images/contact us/monica.jpg";
import UnknownImg from "../../assets/images/contact us/unknown.jpg"

function Contact(params) {
    const {t} = useTranslation();


    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    // const [validated, setValidated] = useState(false);
    const [emailCopyChecked, setEmailCopyChecked] = useState(false);
    const [alarmStyle, setAlarmStyle] = useState("");

    // const [token, setToken] = useState("");
    // const [numbers, setNumbers] = useState([]);
    // const [answer, setAnswer] = useState("");
    // const [wrongFlag, setWrongFlag] = useState(false);

    const [privacyChecked, setPrivacyChecked] = useState(false);

    const [showPrivacyModal, setShowPrivacyModal] = useState(false);

    const [sent, setSent] = useState(false);
    const [errorSending, setErrorSending] = useState(false);
    const [loading, setLoading] = useState(false);

    function handleSubmit() {
        //event.preventDefault();
        console.log("sdf")
        sendEmail();
    }


    // const validate = () => {
    //     // axios
    //     //     .post(`${appConstants.baseURL}/validateAnswer`, {
    //     //         token: token,
    //     //         answer: answer,
    //     //     })
    //     //     .then((data) => {
    //     //         setAnswer("");
    //     //         setValidated(true);
    //     //     })
    //     //     .catch((err) => {
    //     //         console.log("err");
    //     //         setValidated(false);
    //     //         setWrongFlag(true);
    //     //         setTimeout(() => {
    //     //             setWrongFlag(false);
    //     //         }, 3000);
    //     //     });
    // };

    // useEffect(() => {
    //     if (token.length === 0) {
    //         // axios.get(`${appConstants.baseURL}/getQuestion`).then(value => {
    //         //     setNumbers(value.data.numbers);
    //         //     setToken(value.data.token);
    //         // });
    //     }
    // }, [token]);

    function sendEmail() {
        if(firstname.length === 0){
            setAlarmStyle("firstname")
            return;
        }
        if (lastname.length === 0) {
            setAlarmStyle("lastname")
            return;
        }
        if (email.length === 0 || !isValidEmail(email)) {
            setAlarmStyle("email")
            return;
        }
        if (subject.length === 0) {
            setAlarmStyle("subject")
            return;
        }
        if (message.length === 0) {
            setAlarmStyle("message")
            return;
        }
        setAlarmStyle("");
        let input = {
            emailCopyChecked: emailCopyChecked,
            firstname: firstname,
            lastname: lastname,
            email: email,
            subject: subject,
            message: message,
        };
        // if (!validated) {
        //     return;
        // }
        // setValidated(false);
        setFirstname("");
        setLastname("");
        setEmail("");
        setSubject("");
        setMessage("");
        setEmailCopyChecked(false);
        setPrivacyChecked(false);
        setLoading(true);
        // setToken("");
        // setNumbers([]);
        // console.log(input);
        handleLoaded(input)
    }



    const handleLoaded = (input) => {
        console.log("sd")
        window.grecaptcha.ready(_ => {
            window.grecaptcha
                .execute(process.env.REACT_APP_SITE_KEY, { action: "submit" })
                .then(token => {
                    // Add your logic to submit to your backend server here.
                    // console.log(token, input);
                    emailServices.sendEmail(input, token).then(res => {
                        console.log("send", res);
                        setLoading(false);
                        setSent(true);
                        setTimeout(()=>{
                            setSent(false);
                        }, 3000);
                    }).catch(err=>{
                        setLoading(false);
                        setErrorSending(true);
                        setTimeout(() => {
                            setErrorSending(false);
                        }, 3000);
                    })
                })
        })
    }

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    useEffect(() => {
        // Add reCaptcha
        const script = document.createElement("script")
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_SITE_KEY}`
        // script.addEventListener("load", handleLoaded)
        document.body.appendChild(script)
    }, [])

    return (
        <div className='Contact-Container'>
            <div className="parent-container-x-margins margin-top-2-rem">
            {/* <img src={contactUsImg} alt="thirve" className="Home-Block-Image-0 image-motion-2 image-m-2" /> */}
            <div className="ContactUs-Form">
                    <span className="About-Img-Wrap-Big bold">
                        {t("Get in Touch")}
                    </span>
                <div className="Contact-Form-Input row-gap-1-rem column-gap-1-rem display-flex flex-direction-column Contact-Input-Width">
                    <div className="display-flex flex-direction-row row-gap-0-5-rem column-gap-0-5-rem flex-wrap">
                        <input type="text" name="firstname" placeholder={t("First Name")} className={`Contact-Input-Field flex-grow-1 flex-shrink-1 height-3-rem ${alarmStyle === "firstname" ? "alarm-border" : ""}`} value={firstname} onChange={(e) => setFirstname(e.target.value)} />
                        <input type="text" name="lastname" placeholder={t("Last Name")} className={`Contact-Input-Field flex-grow-1 flex-shrink-1 height-3-rem ${alarmStyle === "lastname" ? "alarm-border" : ""}`} value={lastname} onChange={(e) => setLastname(e.target.value)} />
                    </div>

                    <div className="display-flex  flex-direction-row row-gap-0-5-rem column-gap-0-5-rem ">
                        <input type="email" name="email" placeholder={t("Email")} className={`Contact-Input-Field flex-grow-1 height-3-rem ${alarmStyle === "email" ? "alarm-border" : ""}`} value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="display-flex  flex-direction-row row-gap-0-5-rem column-gap-0-5-rem ">

                        <input type="text" name="subject" placeholder={t("Subject")} className={`Contact-Input-Field flex-grow-1 height-3-rem ${alarmStyle === "subject" ? "alarm-border" : ""}`} value={subject} onChange={(e) => setSubject(e.target.value)} />
                    </div>
                    <div className="display-flex  flex-direction-row row-gap-0-5-rem column-gap-0-5-rem ">


                        <textarea type="text" name="message" placeholder={t("Comment of Message")} className={` Contact-Input-Field flex-grow-1 height-10-rem ${alarmStyle === "message" ? "alarm-border" : ""}`} value={message} onChange={(e) => setMessage(e.target.value)} />
                    </div>




                </div>


                <div>
                    <span className="bold font-size-20-px">{t("Privacy")+" *"}</span>
                    <div className="display-flex row-gap-0-5-rem column-gap-0-5-rem ">
                        <input type="checkbox" checked={privacyChecked} onChange={(e) => setPrivacyChecked(e.currentTarget.checked)} />

                        <span>{t("I agree")}</span>
                        <span className="cursor-pointer color-black bold underline" onClick={() => { setShowPrivacyModal(true) }}>{t("Privacy Policy")+" *"} </span>
                        <PrivacyPolicy showModal={showPrivacyModal} setShowModal={setShowPrivacyModal} />
                    </div>
                </div>


                {/* {token?.length > 0 && !validated ?
                    (<div className="display-flex margin-top-1-rem">
                        {numbers[0] + " + " + numbers[1] + " = "}
                        <input className="width-5-rem margin-left-5-px" type="text" value={answer} onChange={e => setAnswer(e.currentTarget.value)} />
                        <button className={`margin-left-5-px background-transparent ${wrongFlag ? "color-orange border-light-orange " : "color-blue-hover cursor-pointer border-light-blue"}`} onClick={() => validate()} disabled={wrongFlag}>Validate</button>
                    </div>)
                    : validated ? <div className="display-flex margin-top-1-rem color-blue">Validated ✔</div> : null}

                {(wrongFlag && token.length > 0) ? <div className="display-flex margin-top-1-rem color-orange">Wrong answer ⚠</div> : !token.length ? <Spinner animation="border" variant="warning" /> : null} */}

                <div className="margin-top-1-rem"><input type="checkbox" checked={emailCopyChecked} onChange={(e) => setEmailCopyChecked(e.currentTarget.checked)} />{" "+ t("Email a Copy") }</div>

                <div className={"Contact-Form-Button"}>
                    <button className={`Contact-Submit ${(privacyChecked) ? "validated-button-submit" : "invalidated-button-submit"} ${sent? "sent-style": errorSending? "error-style": ""} `} onClick={() => { handleSubmit() }} disabled={!privacyChecked || loading}>
                        {loading ? <Spinner animation="border" variant="secondary" /> :
                            sent ? t("Sent") : errorSending ? t("Error") : t("Send") 
                        }
                        
                    </button>
                </div>

            </div>

            <div className="display-flex flex-wrap justify-content-center aling-items-center column-gap-40-px row-gap-40-px margin-top-40-px">
                <div className="Contact-Name-Card">
                    <img className="Contact-Card-Img" src={MameImg} alt="Mame Img" />
                    <span className="text-center">{t("Mame Antwi - Community Outreach and Engagement Coordinator")}</span>
                    <a className="text-decoration-none bold" href="mailto:mame@whiwh.com">
                        <AiOutlineMail />
                        {" mame@whiwh.com"}
                    </a>
                    <span>{"416-593-7655 ext. 4888"}</span>
                </div>
                <div className="Contact-Name-Card">
                    <img className="Contact-Card-Img" src={MonicaImg} alt="Monika Img" />
                    <span className="text-center">{t("Monica Bagaya - Project Management Coordinator")}</span>
                    <a className="text-decoration-none bold" href="mailto:monica@whiwh.com">
                        <AiOutlineMail />
                        {" monica@whiwh.com"}
                    </a>
                    <span>{"416-593-7655"}</span>
                </div>
                <div className="Contact-Name-Card">
                    <img className="Contact-Card-Img" src={UnknownImg} alt="Tomilola Img" />
                    <span className="text-center">{t("Tomilola John - Determinants of Health Program Team Lead")}</span>
                    <a className="text-decoration-none bold" href="mailto:tomilola@whiwh.com">
                        <AiOutlineMail />
                        {" tomilola@whiwh.com"}
                    </a>
                    <span>{"416-593-7655 ext. 4807"}</span>
                </div>
            </div>
            
            </div>

        </div>
    )
}
export default Contact;