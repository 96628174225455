import axios from "axios";
import { appConstants } from "../constants/appConstants";
// import Cookies from 'universal-cookie';

// const cookies = new Cookies();
// function insertImage(imageObject) {
//     return new Promise((resolve, reject) => {
//         try {
//             axios.post(`${appConstants.BASE_URL}/${"sqlInsertImage"}`, imageObject).then(data => {
//                 console.log("data stored ", data);
//                 resolve(data);
//             }).catch(err => {
//                 console.log("someting wend wrong ", err);
//                 reject(err);
//             })
//         }
//         catch (err) {
//             reject(err);
//         }
//     })
// }

function getImages() {
    return new Promise(async (resolve1, reject1) => {
        try {
            //console.log(appConstants.BASE_URL);
            axios.get(`${appConstants.BASE_URL}/${"sqlGetImages"}`).then(data => {
                //console.log(data.data);
                resolve1(data.data);
            })
                .catch(err => {
                    console.log("someting wend wrong ", err);
                    reject1(err);
                })
        }
        catch (err) {
            reject1(err);
        }
    })
}


function getPins() {
    return new Promise(async (resolve1, reject1) => {
        try {
            //console.log(appConstants.BASE_URL);
            axios.get(`${appConstants.BASE_URL}/${"sqlGetPins"}`).then(data => {
                //console.log(data.data);
                resolve1(data.data);
            })
                .catch(err => {
                    console.log("someting wend wrong ", err);
                    reject1(err);
                })
        }
        catch (err) {
            reject1(err);
        }
    })
}

function getPodcasts() {
    return new Promise(async (resolve1, reject1) => {
        try {
            //console.log(appConstants.BASE_URL);
            axios.get(`${appConstants.BASE_URL}/${"sqlGetPodcasts"}`).then(data => {
                //console.log(data.data);
                resolve1(data.data);
            })
                .catch(err => {
                    console.log("someting wend wrong ", err);
                    reject1(err);
                })
        }
        catch (err) {
            reject1(err);
        }
    })
}

function getHomeTickets() {
    return new Promise(async (resolve1, reject1) => {
        try {
            //console.log(appConstants.BASE_URL);
            axios.get(`${appConstants.BASE_URL}/${"sqlGetHomeTickets"}`).then(data => {
                //console.log(data.data);
                resolve1(data.data);
            })
                .catch(err => {
                    console.log("someting wend wrong ", err);
                    reject1(err);
                })
        }
        catch (err) {
            reject1(err);
        }
    })
}

function getResourcesVideos() {
    return new Promise(async (resolve1, reject1) => {
        try {
            //console.log(appConstants.BASE_URL);
            axios.get(`${appConstants.BASE_URL}/${"sqlGetResourcesVideos"}`).then(data => {
                //console.log(data.data);
                resolve1(data.data);
            })
                .catch(err => {
                    console.log("someting wend wrong ", err);
                    reject1(err);
                })
        }
        catch (err) {
            reject1(err);
        }
    })
}

function getResourcesLinks() {
    return new Promise(async (resolve1, reject1) => {
        try {
            //console.log(appConstants.BASE_URL);
            axios.get(`${appConstants.BASE_URL}/${"sqlGetResourcesLinks"}`).then(data => {
                //console.log(data.data);
                resolve1(data.data);
            })
                .catch(err => {
                    console.log("someting wend wrong ", err);
                    reject1(err);
                })
        }
        catch (err) {
            reject1(err);
        }
    })
}

function getResourcesWebinars() {
    return new Promise(async (resolve1, reject1) => {
        try {
            //console.log(appConstants.BASE_URL);
            axios.get(`${appConstants.BASE_URL}/${"sqlGetResourcesWebinars"}`).then(data => {
                //console.log(data.data);
                resolve1(data.data);
            })
                .catch(err => {
                    console.log("someting wend wrong ", err);
                    reject1(err);
                })
        }
        catch (err) {
            reject1(err);
        }
    })
}


function getSurveyQuestions() {
    return new Promise(async (resolve1, reject1) => {
        try {
            //console.log(appConstants.BASE_URL);
            axios.get(`${appConstants.BASE_URL}/${"sqlGetSurveyQuestions"}`).then(data => {
                //console.log(data.data);
                resolve1(data.data);
            })
                .catch(err => {
                    console.log("someting wend wrong ", err);
                    reject1(err);
                })
        }
        catch (err) {
            reject1(err);
        }
    })
}

function uploadSurveyQuestion(obj) {
    return new Promise(async (resolve1, reject1) => {
        try {
            axios.post(`${appConstants.BASE_URL}/surveyQuestion`, { "surveyQuestion": obj}).then(data => {
                console.log(data);
                const imageObject = data.data.imageObject;
                console.log(imageObject)
                resolve1(imageObject)

            })
        }
        catch (err) {
            reject1(err)
        }
    })

}

// function uploadMedia(obj) {
//     return new Promise(async (resolve1, reject1) => {
//         try {
//             let cookie = cookies.get("admin-user-cookie");
//             if (!cookie) {
//                 reject1({ "err": "unuthorized" })
//             }
//             let [email, adminCookie] = cookie.split("%");
//             axios.post(`${appConstants.BASE_URL}/admin/uploadImage`, { "mediaObject": obj, "adminCredentials": { "email": email, "cookie": adminCookie } }).then(data => {
//                 console.log(data);
//                 const imageObject = data.data.imageObject;
//                 console.log(imageObject)
//                 resolve1(imageObject)

//                 // let ds = document.getElementById("gg");
//                 // ds.src = fileLocataion;
//             })
//         }
//         catch (err) {
//             reject1(err)
//         }
//     })

// }

// function uploadTicket(obj) {
//     return new Promise(async (resolve1, reject1) => {
//         try {
//             let cookie = cookies.get("admin-user-cookie");
//             if (!cookie) {
//                 reject1({ "err": "unuthorized" })
//             }
//             let [email, adminCookie] = cookie.split("%");
//             axios.post(`${appConstants.BASE_URL}/admin/uploadTicket`, { "mediaObject": obj, "adminCredentials": { "email": email, "cookie": adminCookie } }).then(data => {
//                 console.log(data);
//                 const imageObject = data.data.imageObject;
//                 console.log(imageObject)
//                 resolve1(imageObject)

//                 // let ds = document.getElementById("gg");
//                 // ds.src = fileLocataion;
//             })
//         }
//         catch (err) {
//             reject1(err)
//         }
//     })

// }


// function updatePin(obj, id) {
//     return new Promise(async (resolve1, reject1) => {
//         try {
//             let cookie = cookies.get("admin-user-cookie");
//             if(!cookie){
//                 reject1({"err": "unuthorized"})
//             }
//             let [email, adminCookie] = cookie.split("%");

//             axios.put(`${appConstants.BASE_URL}/admin/updatePin`, { "pinObject": obj, "pin_id": id, "adminCredentials": {"email": email, "cookie": adminCookie}}).then(data => {
//                 console.log(data);
//                 const imageObject = data.data.message;
//                 console.log(imageObject)
//                 resolve1(imageObject)
//             })
//         }
//         catch (err) {
//             reject1(err)
//         }
//     })

// }

function addPin(obj) {
    return new Promise(async (resolve1, reject1) => {
        try {
            // let cookie = cookies.get("admin-user-cookie");
            // if (!cookie) {
            //     reject1({ "err": "unuthorized" })
            // }
            // let [email, adminCookie] = cookie.split("%");
            // axios.post(`${appConstants.BASE_URL}/admin/addPin`, { "pinObject": obj, "adminCredentials": { "email": email, "cookie": adminCookie } }).then(data => {
            axios.post(`${appConstants.BASE_URL}/admin/addPin`, { "pinObject": obj}).then(data => {
                console.log(data);
                const d = data.data;
                console.log(d)
                resolve1(d)
            })
        }
        catch (err) {
            reject1(err)
        }
    })

}

// function removePin(id) {
//     return new Promise(async (resolve1, reject1) => {
//         try {
//             let cookie = cookies.get("admin-user-cookie");
//             if (!cookie) {
//                 reject1({ "err": "unuthorized" })
//             }
//             let [email, adminCookie] = cookie.split("%"); //TODO
//             axios.post(`${appConstants.BASE_URL}/admin/removePin`, { "pin_id": id, "adminCredentials": { "email": email, "cookie": adminCookie } }).then(data => {
//                 console.log(data);
//                 const d = data.data;
//                 console.log(d)
//                 resolve1(d)
//             })
//         }
//         catch (err) {
//             reject1(err)
//         }
//     })

// }


const mysqlServices = {
    // insertImage,
    getImages,
    // uploadMedia,
    getPins,
    // updatePin,
    addPin,
    // removePin,
    getPodcasts,
    getSurveyQuestions,
    uploadSurveyQuestion,
    // uploadTicket,
    getHomeTickets,
    getResourcesVideos,
    getResourcesLinks,
    getResourcesWebinars
}

export default mysqlServices