
// import Home from './components/home.component/home.component';
import MapFunction from './components/map.component/map.component';
import Header from './components/header.component/header.component';
import NoMatch from './components/noMatch.component/noMatch.component';
// import Resources from './components/resources.component/resources.component';
import Contact from './components/contact.component/contact.component';
import About from './components/about.component/about.component';
import Footer from './components/footer.component/footer.component';
import AdminPage from './components/admin.component/adminPage.component';
// import ImagesPage from './components/admin.component/imagesPage.component';
// import CoverYourTrack from './components/coverYourTrack.component/coverYourTrack';
// import Cookies from 'universal-cookie';
import GetInvolved from './components/getInvolved.component/getInvolved.component';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { Suspense, lazy, useRef, useEffect, createContext, useState } from "react";
import Home from './components/home.component/home.component';



export const AdminContext = createContext({ isAdminLoggedIn: "false" });


function App() {
 

  const [adminLoggedIn, setAdminLoggedIn] = useState("false");

  const handleAdminLogin = (value) => {
    console.log("settting", value)
    setAdminLoggedIn(value)
  }


  useEffect(() => {
    // const state = localStorage.getItem("admin-user");
    // const cookies = new Cookies();
    // const cookieState = cookies.get('admin-user-cookie');
    // if (state) {
    //   setAdminLoggedIn(state)
    // }
    // if (cookieState) {
    //   setAdminLoggedIn("true")
    // }
  }, []);

  return (
    <AdminContext.Provider value={adminLoggedIn}>
      <BrowserRouter>
        <Header />
        <Suspense fallback={<div>Loading ...</div>}>
          <Routes>
            <Route path="*" element={<NoMatch />} />
            <Route path="/" element={<Home />} />
            <Route path="/:pinIdParam" element={<MapFunction />} />
            <Route path="/admin-page" element={<AdminPage setAdminLoggedIn={handleAdminLogin} />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/get-involved" element={<GetInvolved />} />
            {/* <Route path="/map" element={<MapFunction />} /> */}
            {/* <Route path="/resources/:selected" element={<Resources />} />
            
       
            <Route path="/images-page" element={<ImagesPage />} /> */}
            {/* <Route path="/cover-your-track" element={<CoverYourTrack />} /> */}
          </Routes>
        </Suspense>
        <Footer />
      </BrowserRouter>
    </AdminContext.Provider>
  );
}

export default App;
// export default router;