import React, { useEffect, useLocation, useRef, useState, useContext, useMemo } from 'react';
// import * as ReactDOM from 'react-dom/client';
import { withRouter, useParams, useNavigate, Link } from 'react-router-dom';
import mapboxgl from "mapbox-gl";
import "./map.css"
import 'mapbox-gl/dist/mapbox-gl.css'; //this file is very imprtant for the map to adjust when zooming 
import mysqlServices from "../../services/mysqlServices";
import { IoMdPin } from "react-icons/io";
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import { AiFillCloseCircle } from "react-icons/ai";
import { AdminContext } from "../../App";
import Spinner from 'react-bootstrap/Spinner';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { GrFormClose } from "react-icons/gr";
import { useTranslation } from "react-i18next";
import { ReactDOM } from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiLayers } from "react-icons/fi";
import { AiOutlineMail, AiOutlineHome } from "react-icons/ai";
import { FaSnapchat } from "react-icons/fa6";
import { CiYoutube, CiLinkedin } from "react-icons/ci";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { CgWebsite } from "react-icons/cg";
import { LiaAccessibleIcon } from "react-icons/lia";
import { LuCalendarClock } from "react-icons/lu";

// import geoJson from "./chicago-parks.json";
mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN;


function MarkerN() {
    return (
        <div>yayayayayay</div>
    )
}







function MapFunction(props) {
    const adminState = useContext(AdminContext)
    const { pinIdParam } = useParams()

    /**
     * The useRef Hook allows you to persist values between renders.
        It can be used to store a mutable value that does not cause a re-render when updated.
        It can be used to access a DOM element directly.
        const count = useRef(0). It's like doing this: const count = {current: 0}.
     */
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(-73.620584);
    const [lat, setLat] = useState(45.529191);
    const [zoom, setZoom] = useState(5);
    const mapStyle = 'mapbox://styles/mapbox/streets-v11';


    const loaded = useRef(false);


    const [selectedPin, setSelectedPin] = useState(null);

    const [provinceFilter, setProvinceFilter] = useState("Select");
    const [areaFilter, setAreaFilter] = useState("Select");
    const [violenceFilter, setViolenceFilter] = useState("Select");
    const [serviceFilter, setServiceFilter] = useState("Select");

    const areaOptions = useRef({});

    const { t } = useTranslation();

    const provinces = [
        "Select", "Durham", "Halton", "York", "Peel", "Toronto", "Ontario"
    ]
    const violenceTypes = [
        "Select", "Domestic Violence", "Sexual Violence", "Human Trafficking", "Female Genital Mutilation/Cutting"
    ]
    const services = [
        "Select", "Immigration and Settlement Support", "Legal Clinic", "Psychosocial Support", "Groups and Workshops", "Employment Support", "Housing services and Shelter", "Food Access", "Advocacy and Referrals", "Counselling", "Psychotherapy", "Crisis Services", "Primary Health Care", "Sexual and Reproductive Health", "Maternal and Child Health", "Reconstructive Surgery"
    ]




    // const [viewPort, setViewPort] = useState({
    //     width: "600px",
    //     height: "600px",
    //     latitude: 40.7250863,
    //     longitude: -73.9773608,
    //     zoom: 12
    // })

    const signOutStyle = { color: 'red', float: 'right' };
    const getLocationStyle = { backgroundColor: 'grey', position: 'relative', float: 'none' };
    var welcomeMessegeStyle = { color: 'green', float: 'right', marginRight: "60px" };

    const [allPins, setAllPins] = useState(null);
    // const [pins, setPins] = useState(null);
    const [markers, setMarkers] = useState([])
    const [selectedPins, setSelectedPins] = useState([])

    const [selectedMarker, setSelectedMarker] = useState(null);

    const [spinnerOn, setSpinnerOn] = useState(false)

    useEffect(() => {
        console.log(pinIdParam);
    }, [pinIdParam])


    useEffect(() => {
        setAdminPin({ ...selectedMarker })
    }, [selectedMarker])

    const [adminPin, setAdminPin] = useState(null)

    const [errorMessage, setErrorMessage] = useState(null)

    const mapStyles = [
        {
            "name": "Default",
            "link": "mapbox://styles/mapbox/streets-v11"
        },
        {
            "name": "Dark",
            "link": "mapbox://styles/mapbox/dark-v10"
        },
        {
            "name": "Satellite",
            "link": "mapbox://styles/mapbox/satellite-v9"
        },
        {
            "name": "Outdoors",
            "link": "mapbox://styles/mapbox/outdoors-v11"
        },
        {
            "name": "Light",
            "link": "mapbox://styles/mapbox/light-v10"
        },
        {
            "name": "Navigation",
            "link": "mapbox://styles/mapbox/navigation-day-v1"
        }
    ]


    const currentPop = useRef(null)




    let wordMatches = (allObjects, word) => {
        console.log(allObjects)
        let matches = [];
        for (let i = 0; i < allObjects.length; i++) {
            if (allObjects[i]) {
                let objectString = Object.values(allObjects[i]).join("")
                // console.log(objectString);
                // console.log(allObjects[i].name)
                // let name = allObjects[i].name;
                // console.log(name)
                let str = "" + word.toLowerCase() + "";
                //console.log(str);
                let re1 = new RegExp(str, "g")
                //console.log("?>>", firstTry);
                let foundShort = re1.exec(objectString.toLowerCase())
                if (foundShort) {
                    // console.log("found => ", name)
                    matches.push(allObjects[i]);
                }

                // console.log(word.match(re))
                // if (re.test(name)) {
                //     console.log(word, name)
                //     matches.push(name);
                // }
            }

        }
        return matches;
    }






    let newHtml = (feature) => {
        let phoneNumbers = [];
        let phoneDiv = `<div class='display-flex column-gap-0-5-rem'>
                        <p class="margin-5-px">Phone </p><div class="">`
        if (feature.phone) {
            phoneNumbers = feature.phone.split("%");
            phoneNumbers.map(item => {
                phoneDiv += `${item}\n`
            })
        }
        phoneDiv += `</div></div>`;
        //less than two because by default we have "" in the database
        if (phoneNumbers.length < 1 || (phoneNumbers.length === 1 && phoneNumbers[0] === "")) {
            phoneDiv = "";
        }

        let services = [];
        let filterDiv = `<div class='display-flex column-gap-0-5-rem'>
                        <p class="margin-3-px">Services </p><div class="">`
        if (feature.filters) {
            services = feature.filters.split("%");
            services.map(item => {
                filterDiv += `<p class="background-color-light-pink width-fit border-radius-5-px padding-2-px margin-3-px">${item}</p>`
            })
        }

        filterDiv += `</div></div>`;
        if (services.length < 1 || (services.length === 1 && services[0] === "")) {
            filterDiv = "";
        }

        let violence = [];
        let remarksDiv = `<div class='display-flex column-gap-0-5-rem'>
                        <p class="margin-3-px">Type of violence </p><div class="">`

        if (feature.remarks) {
            violence = feature.remarks.split("%");
            violence.map(item => {
                remarksDiv += `<p class="background-color-light-pink width-fit border-radius-5-px padding-2-px margin-3-px">${item}</p>`
            })
        }

        remarksDiv += `</div></div>`;
        if (violence.length < 1 || (violence.length === 1 && violence[0] === "")) {
            remarksDiv = "";
        }

        const html =
            `
                <div class='Pin'>
                    <div class='display-flex column-gap-0-5-rem margin-3-px'>
                    <p class='bold margin-3-px'>${feature.name}</p>
                    ${(feature.area !== null && feature.area !== "") ? `<p class="margin-3-px bold">${feature.area}</p>` : ""}
                    </div>

                    ${(feature.description !== "" && feature.description !== null) ? `<p class="margin-3-px">${feature.description}</p>` : ""}

         

                    ${(feature.address !== null && feature.address !== "") ?
                `<p class="margin-3-px bold">${feature.address}</p>
                    </div >` : ""
            }

            <span class="margin-3-px Read_More-Button" id="wematter_pin_id_${feature.pin_id}" >Read more ➡️</span>
                    
                    
             




        

                </div>
            `

        // const html = 
        // `
        //         <div class='Pin'>
        //             <a href="${feature.pin_id}" >open</a>
        //             <span id="wematter_pin_id_${feature.pin_id}" >open</span>

        //             <div class='display-flex column-gap-0-5-rem margin-3-px'>
        //                 <p class='bold margin-3-px'>${feature.name}</p>
        //             </div>

        //             ${(feature.description !== "" && feature.description !== null) ? `<p class="margin-3-px">${feature.description}</p>` : ""}

        //                   ${(feature.area !== null && feature.area !== "") ?
        //         `<p class="margin-3-px bold">${feature.area}</p>
        //             </div >` : ""
        //     }

        //             ${(feature.address !== null && feature.address !== "") ?
        //         `<p class="margin-3-px bold">${feature.address}</p>
        //             </div >` : ""
        //     }





        //                    <!--instagram -------------------------------------------- -->
        //             ${(feature?.instagram && feature.instagram !== "") ?
        //         `

        //             <a href="#hashLink">

        //                 <svg xmlns="http://www.w3.org/2000/svg" height="1.7em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#f18d91}</style><path d="M224,202.66A53.34,53.34,0,1,0,277.36,256,53.38,53.38,0,0,0,224,202.66Zm124.71-41a54,54,0,0,0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71.05-6.43,94.33S91,329.26,99.32,350.33a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54,54,0,0,0,30.41-30.41c8.35-21,6.43-71.05,6.43-94.33S357.1,182.74,348.75,161.67ZM224,338a82,82,0,1,1,82-82A81.9,81.9,0,0,1,224,338Zm85.38-148.3a19.14,19.14,0,1,1,19.13-19.14A19.1,19.1,0,0,1,309.42,189.74ZM400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM382.88,322c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67.05C384.37,216.44,384.37,295.56,382.88,322Z" /></svg>

        //             </a>
        //             <div id="hashLink">

        //                 <a href="#"><span class="bold">^</span> Collapse</a>
        //             </div>`
        //         : ""}
        //         <!-- -------------------------------------------- -->






        //         <!--facebook -------------------------------------------- -->
        //             ${(feature?.facebook && feature.facebook !== "") ?
        //         `

        //             <a href="#hashLink">

        //                        <svg xmlns="http://www.w3.org/2000/svg" height="1.7em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#f18d91}</style><path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/></svg>

        //             </a>
        //             <div id="hashLink">

        //                 <a href="#"><span class="bold">^</span> Collapse</a>
        //             </div>`
        //         : ""}
        //         <!-- -------------------------------------------- -->


        //         <!--linkedin -------------------------------------------- -->
        //             ${(feature?.linkedin && feature.linkedin !== "") ?
        //         `

        //             <a href="#hashLink">

        //                          <svg xmlns="http://www.w3.org/2000/svg" height="1.7em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#f18d91}</style><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg>

        //             </a>
        //             <div id="hashLink">

        //                 <a href="#"><span class="bold">^</span> Collapse</a>
        //             </div>`
        //         : ""}
        //         <!-- -------------------------------------------- -->



        //         <!--linkedin -------------------------------------------- -->
        //             ${(feature?.linkedin && feature.linkedin !== "") ?
        //         `

        //             <a href="#hashLink">

        //                 <svg xmlns="http://www.w3.org/2000/svg" height="1.7em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#f18d91}</style><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"/></svg>

        //             </a>
        //             <div id="hashLink">

        //                 <a href="#"><span class="bold">^</span> Collapse</a>
        //             </div>`
        //         : ""}
        //         <!-- -------------------------------------------- -->




        //             </div>


        //                   <div class='display-flex column-gap-0-5-rem margin-3-px'>
        //                                       <!--! Hours Of Operation -->

        //       <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 640 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#f18d91}</style><path d="M184 48H328c4.4 0 8 3.6 8 8V96H176V56c0-4.4 3.6-8 8-8zm-56 8V96H64C28.7 96 0 124.7 0 160v96H192 352h8.2c32.3-39.1 81.1-64 135.8-64c5.4 0 10.7 .2 16 .7V160c0-35.3-28.7-64-64-64H384V56c0-30.9-25.1-56-56-56H184c-30.9 0-56 25.1-56 56zM320 352H224c-17.7 0-32-14.3-32-32V288H0V416c0 35.3 28.7 64 64 64H360.2C335.1 449.6 320 410.5 320 368c0-5.4 .2-10.7 .7-16l-.7 0zm320 16a144 144 0 1 0 -288 0 144 144 0 1 0 288 0zM496 288c8.8 0 16 7.2 16 16v48h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H496c-8.8 0-16-7.2-16-16V304c0-8.8 7.2-16 16-16z"/></svg>

        //       <span>Wait Times</span>


        //       </div>

        //             </div>


        //             <div class='display-flex column-gap-0-5-rem margin-3-px'>
        //             <!--! Hours Of Operation -->

        //             <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#f18d91}</style><path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192H400V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192z"/></svg>

        //             <span>Wait Times</span>

        //                   </div>

        //             <div class='display-flex column-gap-0-5-rem margin-3-px'>
        //             <!--! Hours Of Operation -->

        //             <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 640 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#f18d91}</style><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223 149.5c48.6-44.3 123-50.8 179.3-11.7c60.8 42.4 78.9 123.2 44.2 186.9L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3L223 149.5zm223.1 298L83.1 161.5c-11 14.4-20.5 28.7-28.4 42.2l339 265.7c18.7-5.5 36.2-13 52.6-21.8zM34.5 268.3c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c3.1 0 6.1-.1 9.2-.2L33.1 247.8c-1.8 6.8-1.3 14 1.4 20.5z"/></svg>

        //             <span>Vision & Hearing impaired Accommodations</span>

        //                   </div>

        //             <div class='display-flex column-gap-0-5-rem margin-3-px'>
        //             <!--! Hours Of Operation -->

        //             <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 640 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#f18d91}</style><path d="M312 32c-9.7 0-18.5 5.8-22.2 14.8s-1.7 19.3 5.2 26.2l33.4 33.4L275.8 159c-28.4-19.5-62.7-31-99.8-31C78.8 128 0 206.8 0 304s78.8 176 176 176s176-78.8 176-176c0-37-11.4-71.4-31-99.8l52.6-52.6L407 185c6.9 6.9 17.2 8.9 26.2 5.2s14.8-12.5 14.8-22.2V56c0-13.3-10.7-24-24-24H312zm88 48h0v0l0 0zM64 304a112 112 0 1 1 224 0A112 112 0 1 1 64 304zM368 480c97.2 0 176-78.8 176-176c0-37-11.4-71.4-31-99.8l52.6-52.6L599 185c6.9 6.9 17.2 8.9 26.2 5.2s14.8-12.5 14.8-22.2V56c0-13.3-10.7-24-24-24H504c-9.7 0-18.5 5.8-22.2 14.8c-1.2 2.9-1.8 6-1.8 9l0 .2v.2c0 6.2 2.5 12.2 7 16.8l33.4 33.4L480 146.7V168c0 22.6-13.6 43.1-34.6 51.7c-.8 .3-1.7 .7-2.5 1C465.7 241.2 480 270.9 480 304c0 61.9-50.1 112-112 112c-5.4 0-10.8-.4-16-1.1c-12.9 20.4-29.1 38.3-48.1 53.1c19.8 7.8 41.4 12 64 12z"/></svg>

        //             <span>Designated LGBTQ+ positive space</span>

        //                   </div>

        //             <div class='display-flex column-gap-0-5-rem margin-3-px'>
        //             <!--! Hours Of Operation -->

        //             <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 640 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#f18d91}</style><path d="M0 128C0 92.7 28.7 64 64 64H256h48 16H576c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H320 304 256 64c-35.3 0-64-28.7-64-64V128zm320 0V384H576V128H320zM178.3 175.9c-3.2-7.2-10.4-11.9-18.3-11.9s-15.1 4.7-18.3 11.9l-64 144c-4.5 10.1 .1 21.9 10.2 26.4s21.9-.1 26.4-10.2l8.9-20.1h73.6l8.9 20.1c4.5 10.1 16.3 14.6 26.4 10.2s14.6-16.3 10.2-26.4l-64-144zM160 233.2L179 276H141l19-42.8zM448 164c11 0 20 9 20 20v4h44 16c11 0 20 9 20 20s-9 20-20 20h-2l-1.6 4.5c-8.9 24.4-22.4 46.6-39.6 65.4c.9 .6 1.8 1.1 2.7 1.6l18.9 11.3c9.5 5.7 12.5 18 6.9 27.4s-18 12.5-27.4 6.9l-18.9-11.3c-4.5-2.7-8.8-5.5-13.1-8.5c-10.6 7.5-21.9 14-34 19.4l-3.6 1.6c-10.1 4.5-21.9-.1-26.4-10.2s.1-21.9 10.2-26.4l3.6-1.6c6.4-2.9 12.6-6.1 18.5-9.8l-12.2-12.2c-7.8-7.8-7.8-20.5 0-28.3s20.5-7.8 28.3 0l14.6 14.6 .5 .5c12.4-13.1 22.5-28.3 29.8-45H448 376c-11 0-20-9-20-20s9-20 20-20h52v-4c0-11 9-20 20-20z"/></svg>

        //             <span>Language </span>

        //                   </div>


        //             <div class='display-flex column-gap-0-5-rem margin-3-px'>
        //             <!--! Hours Of Operation -->

        //             <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#f18d91}</style><path d="M364.2 83.8c-24.4-24.4-64-24.4-88.4 0l-184 184c-42.1 42.1-42.1 110.3 0 152.4s110.3 42.1 152.4 0l152-152c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-152 152c-64 64-167.6 64-231.6 0s-64-167.6 0-231.6l184-184c46.3-46.3 121.3-46.3 167.6 0s46.3 121.3 0 167.6l-176 176c-28.6 28.6-75 28.6-103.6 0s-28.6-75 0-103.6l144-144c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-144 144c-6.7 6.7-6.7 17.7 0 24.4s17.7 6.7 24.4 0l176-176c24.4-24.4 24.4-64 0-88.4z"/></svg>

        //             <span>Required Documentation</span>

        //                   </div>


























        //         <a href="#hashLink"><p><span class="bold">></span> Read More</p></a>
        //         <div id="hashLink">
        //                ${(feature.website && feature.website !== "") ?
        //         `<div class='display-flex column-gap-0-5-rem margin-3-px' >
        //                 <p class="margin-3-px">Website </p>
        //                 <a href=${feature.website} target="_blank" rel="noreferrer">${feature.website}</a>
        //                 </div >`
        //         : ""}

        //                 ${(feature.email && feature.email !== "") ?
        //         `<div class='display-flex column-gap-0-5-rem margin-3-px' >
        //                 <p class="margin-3-px">Email</p>
        //                 <p class="margin-3-px">${feature.email}</p>
        //                 </div >`
        //         : ""}
        //             ${phoneDiv}


        //         <a href="#"><span class="bold">^</span> Collapse</a>
        //         </div>


        //         </div>
        //     `

        return html;

    }



    const markerClicked = (data) => {
        const { name, email, website, description } = data
        // window.alert(`${name}\n${email}\n${website}\n${description}`);
        setSelectedPin(data);
    };

    const markerMouseIn = (data) => {
        window.alert(data.name);
    };

    useEffect(() => {
        console.log(mapContainer)
    }, [])

    useEffect(() => {
        console.log("yay")

        let cancelProcessing = false;

        if (!allPins) { cancelProcessing = true; }

        let newPinsState = allPins
        if (!cancelProcessing && provinceFilter !== "Select") {
            newPinsState = allPins.filter(item => item?.province === provinceFilter).map(item => item)
        }
        if (cancelProcessing || !newPinsState || newPinsState.length < 1) { cancelProcessing = true }


        if (!cancelProcessing && areaFilter !== "Select") {
            newPinsState = allPins.filter(item => item?.area === areaFilter).map(item => item)
        }
        if (cancelProcessing || !newPinsState || newPinsState.length < 1) { cancelProcessing = true }


        else if (violenceFilter !== "Select") {
            let violeneceFiltering = [];
            for (let i = 0; i < newPinsState.length; i++) {
                if (newPinsState[i].remarks) {
                    let itemViolenceArray = newPinsState[i].remarks.split("%")
                    if (itemViolenceArray.length < 1) {
                        continue;
                    } else {
                        for (let k = 0; k < itemViolenceArray.length; k++) {
                            if (itemViolenceArray[k] === violenceFilter) {
                                violeneceFiltering.push(newPinsState[i])
                                break;
                            }
                        }
                    }
                }

            }
            newPinsState = violeneceFiltering;
        }

        if (cancelProcessing || !newPinsState || newPinsState.length < 1) { cancelProcessing = true }
        else if (serviceFilter !== "Select") {
            let serviceFiltering = [];
            for (let i = 0; i < newPinsState.length; i++) {
                if (newPinsState[i].filters) {
                    let itemServiceArray = newPinsState[i].filters.split("%")
                    if (itemServiceArray.length < 1) {
                        continue;
                    } else {
                        for (let k = 0; k < itemServiceArray.length; k++) {
                            if (itemServiceArray[k] === serviceFilter) {
                                serviceFiltering.push(newPinsState[i])
                                break;
                            }
                        }
                    }
                }
            }
            newPinsState = serviceFiltering
        }



        if (cancelProcessing || !newPinsState || newPinsState.length < 1) { cancelProcessing = true }


        console.log(markers)
        if (markers !== null) {
            for (var i = markers.length - 1; i >= 0; i--) {
                markers[i].remove();
            }
        }
        let newMarkers = []
        let newFeature = []

        if (!cancelProcessing) {
            newPinsState.forEach((feature) => {


                // const ref = React.createRef();
                // ref.current = document.createElement("div");
                // const rd = ReactDOM.createRoot(ref.current);
                // rd.render(
                //     <Marker onClick={markerClicked} onMouseIn={markerMouseIn} feature={feature} map={map.current} setSelectedMarker={setSelectedMarker} currentPoppup={currentPop} />
                // )

                let colors = ["#FF5733",
                    "#43D49E",
                    "#FFD700",
                    "#8A2BE2",
                    "#3CB371",
                    "#FF69B4",
                    "#6495ED",
                    "#ADFF2F",
                    "#9932CC",
                    "#FF4500",
                    "#00CED1",
                    "#FF6347",
                    "#1E90FF",
                    "#FF8C00",
                    "#20B2AA"]

                //let pinColor = colors[Math.floor(Math.random() * colors.length)]
                let pinColor = colors[areaOptions.current[feature?.area] % 15]
                let coordinates = [(feature && feature?.lng !== null) ? feature.lng : 0, (feature && feature?.lat !== null) ? feature.lat : 0]
                try {

                    if ((feature.lng && !validCoordinate("lng", feature.lng)) || (feature.lat && !validCoordinate("lat", feature.lat))) {
                        console.log(">>>>>> invalide lng", feature)
                    } else {

                        if (!(feature.lng === 0 && feature.lat === 0)) {
                            var marker = new mapboxgl.Marker({ color: pinColor })
                                .setLngLat(coordinates)
                                .setPopup(new mapboxgl.Popup({ closeOnClick: true }).setHTML(newHtml(feature)))
                                .addTo(map.current);

                            marker.getElement().addEventListener('click', (e) => {
                                setSelectedMarker(feature)
                            });
                            newMarkers.push(marker)
                        }

                        // var marker = new mapboxgl.Marker(ref.current).setLngLat(coordinates).addTo(map.current)
                    
                        newFeature.push(feature)
                    }


                }
                catch (err) {
                    console.log(err)
                }


            })
        }

        // we need to calculate the average lat and lng and make the map fly there
        let totalLng = 0;
        let totalLat = 0;
        let lengthG = 0
        for (let i = 0; i < newFeature.length; i++) {
            console.log(newFeature[i])
            let bothZero = newFeature[i].lng === 0 && newFeature[i].lat === 0
            console.log(bothZero)
            if (newFeature[i].lng && newFeature[i].lat && !bothZero) {
                totalLng += parseFloat(newFeature[i].lng);
                totalLat += parseFloat(newFeature[i].lat);
                lengthG += 1;
            }
        }
        if (newFeature.length > 0) {
            let averageLng = totalLng / lengthG;
            let averageLat = totalLat / lengthG;
            const end = {
                center: [averageLng, averageLat],
                zoom: (provinceFilter === "Select" && violenceFilter === "Select" && serviceFilter === "Select") ? 8 : 9.5,
                bearing: 0,
                pitch: 0
            };
            map.current.flyTo({ ...end, duration: 12000, essential: true })
        }

        setMarkers(newMarkers)
        setSelectedPins(newFeature);
        setSearchResult(newFeature)

    }, [areaFilter, provinceFilter, violenceFilter, serviceFilter, allPins])





    const handleClose = () => {
        setErrorMessage(null);
    }

    useEffect(() => {
        // if (!map.current) return; // wait for map to initialize
        // map.current.on('move', () => {
        //     setLng(map.current.getCenter().lng.toFixed(4));
        //     setLat(map.current.getCenter().lat.toFixed(4));
        //     setZoom(map.current.getZoom().toFixed(2));
        // });
        //console.log(lng, lat, zoom);

        if (!loaded.current) {
            loaded.current = true;


            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: mapStyle,
                center: [lng, lat],
                zoom: zoom,
            });

            map.current.on('load', function () {
                map.current.resize();
            })

            // map.current.on("load", function () {
            //     // Add an image to use as a custom marker
            //     map.current.loadImage(
            //         "https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png",
            //         function (error, image) {
            //             if (error) throw error;
            //             map.current.addImage("custom-marker", image);
            //             // Add a GeoJSON source with multiple points
            //             map.current.addSource("points", {
            //                 type: "geojson",
            //                 data: {
            //                     type: "FeatureCollection",
            //                     features: geoJson.features,
            //                 },
            //             });
            //             // Add a symbol layer
            //             map.current.addLayer({
            //                 id: "points",
            //                 type: "symbol",
            //                 source: "points",
            //                 layout: {
            //                     "icon-image": "custom-marker",
            //                     // get the title name from the source's "title" property
            //                     "text-field": ["get", "title"],
            //                     "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            //                     "text-offset": [0, 1.25],
            //                     "text-anchor": "top",
            //                 },
            //             });
            //         }
            //     );

            // });

            mysqlServices.getPins().then(data => {
                console.log(data);
                let pins = data.data;
                if (!pins) { return }

                for (let p of pins) {
                    if (!areaOptions.current[p?.area]) {
                        let temp = { ...areaOptions.current }
                        temp[p?.area] = Object.keys(areaOptions.current).length;
                        areaOptions.current = temp;
                    }
                }


                console.log(areaOptions)


                setAllPins(pins);

            }).catch(err => {
                console.log(err);
            })


            map.current.on('move', () => {
                // setLng(map.current.getCenter().lng.toFixed(4));
                // setLat(map.current.getCenter().lat.toFixed(4));
                setLng(lng);
                setLat(lat);
                setZoom(map.current.getZoom().toFixed());
            });
            // map.current.on('click', (e) => {
            //     console.log(e)
            //     let { lat, lng } = e.lngLat;

            //     console.log(lng, lat)
            // })
            // map.current.on('zoom', (e) => {
            //     // console.log(e);
            //     console.log(window.location.href, e);
            // })

            map.current.addControl(new mapboxgl.NavigationControl(), "bottom-left");

        }
        // else {
        //     loaded.current = false;
        // }
    }, []);


    function updateStyle(str) {
        // this.setState({...this.state.zoom, mapStyle: str});
        // console.log(this.state);
        map.current.setStyle(str);

    }

    const handleMapStyleChange = (value) => {
        console.log("change ", value)
        updateStyle(value)
    }

    const handleFilterChange = (filterType, value) => {
        console.log(filterType, value)
        switch (filterType) {
            case "area":
                setAreaFilter(value);
                break;
            case "province":
                setProvinceFilter(value);
                break;
            case "violence":
                setViolenceFilter(value);
                break;
            case "service":
                setServiceFilter(value);
                break;
            default:
                break;
        }
    }

    const handleChange = (target, value) => {
        console.log(target, value)
        let newPin = { ...adminPin };
        newPin[target] = value;

        setAdminPin(newPin);
        // switch (target) {
        //     case "name":
        //         setAdminPin({ ...adminPin, "name": value });
        //         break;
        //     case "street_number":
        //         setAdminPin({ ...adminPin, "street_number": value });
        //         break;
        //     default:
        //         break;

        // }
    }

    const validCoordinate = (t, val) => {
        if (t === "lng") {
            if (val <= 180.0 && val >= -180.0) {
                return true;
            } else {
                return false;
            }
        }
        else if (t === "lat") {
            // console.log(t, val, val > 100.0)
            if (val <= 90.0 && val >= -90.0) {
                return true;
            } else {
                return false;
            }
        }
    }
    const updatePin = () => {
        console.log(adminState)
        if (!(adminState === "true")) {
            return;
        }
        if (adminPin.lng && !validCoordinate("lng", adminPin.lng)) {
            console.log("invalide lng")
            setErrorMessage("invalide lng val <= 180.0 && val >= -180.0")
            return;
        }
        if (adminPin.lat && !validCoordinate("lat", adminPin.lat)) {
            console.log("invalide lat val <= 90.0 && val >= -90.0")
            setErrorMessage("invalide lat val <= 90.0 && val >= -90.0")
            return;
        }
        setSpinnerOn(true);
        console.log("updating pin")
        let id = selectedMarker.pin_id;
        let objectWihoutId = { ...adminPin }
        delete objectWihoutId["pin_id"]
        console.log(objectWihoutId)
        mysqlServices.updatePin(objectWihoutId, id).then((data) => {
            console.log(data);
            setSpinnerOn(false);
            // setErrorMessage("Updated successfully ✔️")
        }).catch(err => {
            setSpinnerOn(false)
        })
    }
    const removePin = () => {
        if (!(adminState === "true")) {
            return;
        }
        setSpinnerOn(true);
        console.log("removing pin")
        let id = selectedMarker.pin_id;
        mysqlServices.removePin(id).then((data) => {
            console.log(data);
            setSpinnerOn(false);
            setErrorMessage("Remove successfully ✔️")
        }).catch(err => {
            setSpinnerOn(false);
        })
    }

    const [keyword, setKeyword] = useState("");
    const [searchResult, setSearchResult] = useState([]);

    const handleKeywordChange = (e) => {
        const word = e.currentTarget.value;
        setKeyword(word)
    }

    const handleSearch = () => {
        let result = wordMatches(selectedPins, keyword)
        console.log(result);
        setSearchResult(result)
    }

    const handleSearchResultClose = () => {
        setSearchResult([]);
    }

    const handleResultSelection = (item) => {
        // const start = {
        //     center: [lng, lat],
        //     zoom: zoom,
        //     pitch: 0,
        //     bearing: 0
        // };
        const end = {
            center: [item.lng, item.lat],
            zoom: 15,
            bearing: 0,
            pitch: 0
        };
        // const target = isAtStart ? end : start;
        // isAtStart = !isAtStart;
        map.current.flyTo({ ...end, duration: 12000, essential: true })
    }

    const [readMorePinModalValue, setReadMorePinModalValue] = useState(null)
    function handleMapClick(e) {
        console.log(e)
        if (e?.target?.id?.startsWith("wematter_pin_id_")) {
            const pin_id = e?.target?.id?.split("wematter_pin_id_")
            console.log(pin_id[1])
            if (!pin_id[1]) { return }
            const readMorePin = allPins.filter(item => item.pin_id === parseInt(pin_id[1]))[0]
            if (readMorePin) {
                console.log(readMorePin)
                setReadMorePinModalValue(readMorePin)
            }
        }
    }

    return (
        <div className='relative Map_Container'>
            {/* <input readOnly id="ip" type="text" placeholder="99.238.35.3" value="99.238.35.3"/> */}
            {/* <input onChange={() => { doNothing(); }} id="ip" type="text" placeholder="99.238.35.3" value="99.238.35.3" />
            <button style={getLocationStyle} type="button" onClick={() => { doNothing(); }} className="styles mapButton">Get Location</button>
            <button className="styles mapButton" style={signOutStyle} onClick={() => { signOut(); }}>Sign out</button>
            <p style={welcomeMessegeStyle}>{`Welcome you are logged in as ${email1}`}</p> */}
            <Modal show={errorMessage !== null} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>System Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>{errorMessage + " !"}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {(selectedMarker && adminState === "true") ?
                <div className='Selected_Pin_Container'>

                    <div className='Admin-Pin'>
                        <div className='display-flex column-gap-0-5-rem'>
                            <span className='cursor-pointer icons-close-color' onClick={() => { setSelectedMarker(null) }}>
                                <AiFillCloseCircle />
                            </span>
                        </div>

                        <div className="display-flex">
                            <input type="number" placeholder="lng" value={adminPin.lng ?? ""} onChange={(e) => { handleChange("lng", e.currentTarget.value) }} />
                            <input type="number" placeholder="lat" value={adminPin.lat ?? ""} onChange={(e) => { handleChange("lat", e.currentTarget.value) }} />
                        </div>

                        <input type="text" placeholder="name" value={adminPin.name ?? ""} onChange={(e) => { handleChange("name", e.target.value) }} />

                        <div className="display-flex">
                            <input type="text" placeholder="street number" value={adminPin.street_number ?? ""} onChange={(e) => { handleChange("street_number", e.target.value) }} />
                            <input type="text" placeholder="street name" value={adminPin.street_name ?? ""} onChange={(e) => { handleChange("street_name", e.currentTarget.value) }} />
                            <input type="text" placeholder="suite" value={adminPin.suite ?? ""} onChange={(e) => { handleChange("suite", e.currentTarget.value) }} />
                            <input type="text" placeholder="city" value={adminPin.city ?? ""} onChange={(e) => { handleChange("city", e.currentTarget.value) }} />
                            <input type="text" placeholder="province" value={adminPin.province ?? ""} onChange={(e) => { handleChange("province", e.currentTarget.value) }} />
                            <input type="text" placeholder="postal code" value={adminPin.postal_code ?? ""} onChange={(e) => { handleChange("postal_code", e.currentTarget.value) }} />
                        </div>

                        <input type="text" placeholder="webiste" value={adminPin.website ?? ""} onChange={(e) => { handleChange("website", e.currentTarget.value) }} />
                        <input type="text" placeholder="phone" value={adminPin.phone ?? ""} onChange={(e) => { handleChange("phone", e.currentTarget.value) }} />
                        <input type="text" placeholder="email" value={adminPin.email ?? ""} onChange={(e) => { handleChange("email", e.currentTarget.value) }} />
                        <input type="text" placeholder="remarks" value={adminPin.remarks ?? ""} onChange={(e) => { handleChange("remarks", e.currentTarget.value) }} />
                        <input type="text" placeholder="filters" value={adminPin.filters ?? ""} onChange={(e) => { handleChange("filters", e.currentTarget.value) }} />
                        <input type="text" placeholder="description" value={adminPin.description ?? ""} onChange={(e) => { handleChange("description", e.currentTarget.value) }} />


                        <div className='display-flex'>
                            <button onClick={() => { updatePin() }} >Update Pin</button>
                            <button onClick={() => { removePin() }} >Remove Pin</button>
                            {spinnerOn ? <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner> : null}
                        </div>



                    </div>


                </div>
                : null
            }


            <div className="Search_Result_Container">
                <Form.Select onChange={(e) => { handleMapStyleChange(e.target.value) }}>
                    {mapStyles.map(item => <option className='Styles_Container_Option' value={item.link}>{item.name}</option>)}
                </Form.Select>
                {/* {searchResult.length > 0 ? 
                    <div className='background-color-white'>
                        <div className=''>
                            <span className='padding-1-rem '>Filter Results</span>
                            <span className='float-right  hover-background-color-light-pink cursor-pointer' onClick={()=> {handleSearchResultClose()}}><GrFormClose /></span>
                        </div>
                    
                        <div className='padding-1-rem width-20-rem height-15-rem display-flex flex-direction-column  overflow-y-auto column-gap-0-5-rem row-gap-0-5-rem'>
                            {searchResult.map(item => <span className='background-color-light-pink border-radius-5-px padding-2-px cursor-pointer' onClick={()=>{handleResultSelection(item)}}>{item.name}</span>)}
                        </div>
                    </div>
                   :
                null
                } */}

                <div className='Filters_Container-Left'>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>{t("Pins")}</Accordion.Header>
                            <Accordion.Body>
                                <div className='background-color-white'>
                                    <div className='padding-1-rem  height-15-rem display-flex flex-direction-column  overflow-y-auto column-gap-0-5-rem row-gap-0-5-rem'>
                                        {searchResult.filter(item => !(item.lng === 0 && item.lat === 0)).map(item => <span className='background-color-gradient-1 border-radius-5-px padding-2-px cursor-pointer' onClick={() => { setReadMorePinModalValue(item); handleResultSelection(item); }}>{item.name}</span>)}
                                    </div>
                                </div>


                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                </div>

                <div className='Filters_Container-Left-Middle'>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>{t("Online")}</Accordion.Header>
                            <Accordion.Body>
                                <div className='background-color-white'>
                                    <div className='padding-1-rem  height-15-rem display-flex flex-direction-column  overflow-y-auto column-gap-0-5-rem row-gap-0-5-rem'>
                                        {searchResult.filter(item => (item.lng === 0 && item.lat === 0)).map(item => <span className='background-color-gradient-1 border-radius-5-px padding-2-px cursor-pointer' onClick={() => { 
                                                // const readMorePin = allPins.filter(item => item.pin_id === parseInt(pin_id[1]))[0]
                                                // if (readMorePin) {
                                                //     console.log(readMorePin)
                                                // }
                                            setReadMorePinModalValue(item)
                                         }}>{item.name}</span>)}
                                    </div>
                                </div>


                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                </div>

            </div>


            <div className="Styles_Container">
                <Form.Select onChange={(e) => { handleMapStyleChange(e.target.value) }}>
                    {mapStyles.map(item => <option className='Styles_Container_Option' value={item.link}>{item.name}</option>)}
                </Form.Select>
            </div>

            <div className='Filters_Container'>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>{t("Filters")}</Accordion.Header>
                        <Accordion.Body>
                            <div className='display-flex flex-direction-column column-gap-2-rem row-gap-2-rem'>
                                {/* <div className='display-flex flex-direction-column text-center'>
                                    <p className='margin-bottom-0-5-rem'>{t("Type of Service")}</p>
                                    <Form.Select onChange={(e) => { handleFilterChange("service", e.target.value) }}>
                                        {services.map(item => <option className='Styles_Container_Option' value={item}>{t(item)}</option>)}
                                    </Form.Select>
                                </div> */}
                                {/* <div className='display-flex flex-direction-column text-center'>
                                    <p className='margin-bottom-0-5-rem'>{t("Type of Violence")}</p>
                                    <Form.Select onChange={(e) => { handleFilterChange("violence", e.target.value) }}>
                                        {violenceTypes.map(item => <option className='Styles_Container_Option' value={item}>{t(item)}</option>)}
                                    </Form.Select>
                                </div> */}
                                <div className='display-flex flex-direction-column text-center'>
                                    <p className='margin-bottom-0-5-rem'>{t("Area")}</p>
                                    <Form.Select onChange={(e) => { handleFilterChange("area", e.target.value) }}>
                                        <option value={"Select"}>Select</option>
                                        {Object.keys(areaOptions.current).map(item => <option className='Styles_Container_Option' value={item}>{item}</option>)}
                                    </Form.Select>
                                </div>
                                <div className='display-flex flex-direction-column text-center'>
                                    <div className='display-flex'>
                                        <input className='border-left-radius-5-px' type="text" placeholder='Ex. Acclaim Health' value={keyword} onChange={(e) => { handleKeywordChange(e) }} />
                                        <button className='border-right-radius-5-px ' onClick={() => { handleSearch() }} >Search</button>
                                    </div>
                                </div>





                            </div>

                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

            </div>

            <div className='Selected_Pin_Container'>
                {selectedPin ?
                    <div className='Pin'>
                        <div className='display-flex column-gap-0-5-rem'>
                            <span className='cursor-pointer icons-close-color' onClick={() => { setSelectedPin(null) }}>
                                <AiFillCloseCircle />
                            </span>
                            <p>{selectedPin.name}</p>
                        </div>

                        <div className='display-flex column-gap-0-5-rem'>
                            <p>{"Website:"}</p>
                            <a href={selectedPin.website} target="_blank" rel="noreferrer">{selectedPin.website}</a>
                        </div>
                    </div>


                    : null
                }


            </div>

            <div onClick={(e) => { handleMapClick(e) }} ref={mapContainer} className="Map" />
            {/* <div className="Sidebar">
                Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
            </div> */}

            <Modal size='lg' show={readMorePinModalValue !== null} onHide={() => { setReadMorePinModalValue(null) }} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className='display-flex flex-wrap'>
                            <span className='margin-right-0-5-rem'>
                                {readMorePinModalValue?.name}
                            </span>
                            <span className='margin-right-0-5-rem'>
                                - {readMorePinModalValue?.area}
                            </span>
                            {readMorePinModalValue?.remarks ?
                                <span>
                                    - {readMorePinModalValue?.remarks}
                                </span> : null
                            }

                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='display-flex flex-direction-column row-gap-10-px column-gap-10-px'>
                        {readMorePinModalValue?.description ?
                            <span className='bold'>{readMorePinModalValue?.description}</span>
                            : null}
                        {readMorePinModalValue?.address ?
                            <span className='bold'>{readMorePinModalValue?.address}</span>
                            : null}
                        <span className='Map_Pin_Popup_Separator'></span>
                        {readMorePinModalValue?.hours_of_operation ?
                            <div className='display-flex flex-wrap column-gap-0-5-rem row-gap-0-5-rem'>
                                <span className=''><LuCalendarClock /></span>
                                <span className='bold'>Hours of operation: </span>
                                <span>{readMorePinModalValue?.hours_of_operation}</span>
                            </div>
                            : null}
                        {readMorePinModalValue?.accessability ?
                            <div className='display-flex flex-wrap column-gap-0-5-rem row-gap-0-5-rem'>
                                <span className=''><LiaAccessibleIcon /></span>
                                <span className='bold'>Accessability: </span>
                                <span>{readMorePinModalValue?.accessability}</span>
                            </div>
                            : null}
                        <span className='Map_Pin_Popup_Separator'></span>
                        {readMorePinModalValue?.phone ?
                            <div className='display-flex flex-wrap column-gap-0-5-rem row-gap-0-5-rem'>
                                <span className=''><MdOutlinePhoneInTalk /></span>
                                <span className='bold'>Phone: </span>
                                <span>{readMorePinModalValue?.phone}</span>
                            </div>
                            : null}
                        {readMorePinModalValue?.email ?
                            <div className='display-flex flex-wrap column-gap-0-5-rem row-gap-0-5-rem'>
                                <span className=''><AiOutlineMail /></span>
                                <span className='bold'>Email: </span>
                                <span>{readMorePinModalValue?.email}</span>
                            </div>
                            : null}
                        {readMorePinModalValue?.website ?
                            <div className='display-flex flex-wrap column-gap-0-5-rem row-gap-0-5-rem'>
                                <span className=''><CgWebsite /></span>
                                <span className='bold'>Website: </span>
                                <span>{readMorePinModalValue?.website}</span>
                            </div>
                            : null}
                        <span className='Map_Pin_Popup_Separator'></span>
                        {readMorePinModalValue?.twitter ?
                            <div className='display-flex flex-wrap column-gap-0-5-rem row-gap-0-5-rem'>
                                <span className=''><FiTwitter /></span>
                                <span className='bold'>Twitter: </span>
                                <span>{readMorePinModalValue?.twitter}</span>
                            </div>
                            : null}
                        {readMorePinModalValue?.facebook ?
                            <div className='display-flex flex-wrap column-gap-0-5-rem row-gap-0-5-rem'>
                                <span className=''><FiFacebook /></span>
                                <span className='bold'>Facebook: </span>
                                <span>{readMorePinModalValue?.facebook}</span>
                            </div>
                            : null}
                        {readMorePinModalValue?.youtube ?
                            <div className='display-flex flex-wrap column-gap-0-5-rem row-gap-0-5-rem'>
                                <span className=''><CiYoutube /></span>
                                <span className='bold'>Youtube: </span>
                                <span>{readMorePinModalValue?.youtube}</span>
                            </div>
                            : null}
                        {readMorePinModalValue?.instagram ?
                            <div className='display-flex flex-wrap column-gap-0-5-rem row-gap-0-5-rem'>
                                <span className=''><FiInstagram /></span>
                                <span className='bold'>Instagram: </span>
                                <span>{readMorePinModalValue?.instagram}</span>
                            </div>
                            : null}
                        {readMorePinModalValue?.linkedin ?
                            <div className='display-flex flex-wrap column-gap-0-5-rem row-gap-0-5-rem'>
                                <span className=''><CiLinkedin /></span>
                                <span className='bold'>LinkedIn: </span>
                                <span>{readMorePinModalValue?.linkedin}</span>
                            </div>
                            : null}
                        {readMorePinModalValue?.snapchat ?
                            <div className='display-flex flex-wrap column-gap-0-5-rem row-gap-0-5-rem'>
                                <span className=''><FaSnapchat /></span>
                                <span className='bold'>Snapchat: </span>
                                <span>{readMorePinModalValue?.snapchat}</span>
                            </div>
                            : null}

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setReadMorePinModalValue(null) }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default MapFunction;